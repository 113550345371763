import { isAddress } from 'web3-utils'

export const transformHashForXinfin = (hash: string) => (isAddress(hash) ? hash.replace(/^0x/, 'xdc') : hash)

export const transformHashFromXinfin = (hash: string) => {
  const candidate = hash.replace(/^xdc/, '0x')

  return isAddress(candidate) ? candidate : hash
}

const traverseXdcObjRec = (obj: any): any => {
  const mapValue = (value: any) => {
    if (typeof value === 'string' && /^xdc/.test(value)) {
      return value.replace(/^xdc/, '0x')
    }

    if (typeof value === 'object') {
      return traverseXdcObjRec(value)
    }

    return value
  }

  if (obj instanceof Array) {
    return obj.map(mapValue)
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]: [any, any]): any => [key, mapValue(value)]))
  }

  return obj
}

const adjustWalletRequest = (reqObj: any) => {
  if (reqObj.method === 'eth_requestAccounts') {
    reqObj.method = 'eth_accounts'
  }

  return reqObj
}

const transformToJSONRpc = (reqObj: any) => {
  reqObj = { ...reqObj, jsonrpc: '2.0', id: jsonId++ }

  return reqObj
}

let jsonId = 0

const chainChangedTimers = new Map()
const CHAIN_ID_POLLING_INTERVAL = 1000

export const transformProviderFromXinfin = (provider: any) => {
  const _sendAsync = provider.constructor.prototype.sendAsync

  const _on = provider.constructor.prototype.on
  const _off = provider.constructor.prototype.off

  provider.on = function (e: any, cb: any) {
    if (e === 'chainChanged') {
      let chainId = provider?.publicConfigStore?._state?.networkVersion

      const id = setInterval(() => {
        if (provider?.publicConfigStore?._state?.networkVersion !== chainId) {
          chainId = provider?.publicConfigStore?._state?.networkVersion

          cb(`0x${parseInt(chainId, 10).toString(16)}`)
        }
      }, CHAIN_ID_POLLING_INTERVAL)

      chainChangedTimers.set(cb, id)

      return provider
    }

    return _on.call(this, e, cb)
  }

  provider.off = function (e: any, cb: any) {
    if (e === 'chainChanged') {
      if (!chainChangedTimers.has(cb)) return provider

      clearInterval(chainChangedTimers.get(cb))

      chainChangedTimers.delete(cb)

      return provider
    }

    return _off.call(this, e, cb)
  }

  if (provider.request === undefined) {
    provider.request = function (reqObj: any) {
      reqObj = adjustWalletRequest(reqObj)
      reqObj = transformToJSONRpc(reqObj)

      return new Promise((resolve, reject) => {
        _sendAsync.call(this, reqObj, (error: any, resObj: any) => {
          if (error) {
            reject(error)
          } else {
            resolve(traverseXdcObjRec(resObj.result))
          }
        })
      })
    }
  }

  provider.sendAsync = function (payload: any, callback: any) {
    return _sendAsync.call(this, payload, (error: any, response: any) => {
      callback(error, traverseXdcObjRec(response))
    })
  }

  return provider
}
