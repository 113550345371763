const icon = `
<svg version="1.0" viewBox="0 0 338 339" width="100%"
  xmlns="http://www.w3.org/2000/svg">
  <defs id="defs836" />
  <switch id="switch830">
    <g id="g828">
      <path fill="#b7b5b1" d="m 337.96301,152.84401 c -38.832,-224.980997 -332.5980065,-181.443997 -337.84000648,0 16.95599948,8.798 27.60399948,14.143 27.60399948,14.143 0,0 -8.987,5.381 -27.7269994785157,17.355 C 33.855003,404.90401 322.55901,372.87301 337.90601,184.08801 c -18.355,-11.253 -28.363,-17.191 -28.363,-17.191 0,0 8.584,-4.089 28.42,-14.053 z" id="path824-0" style="fill:#ffffff" />
      <path fill="#b7b5b1" d="m 337.96301,152.84401 c -38.832,-224.980999 -332.5980037,-181.443999 -337.84000365,0 16.95599965,8.798 27.60399965,14.143 27.60399965,14.143 0,0 -8.987,5.381 -27.7269996523438,17.355 C 33.855006,404.90401 322.55901,372.87301 337.90601,184.08801 c -18.355,-11.253 -28.363,-17.191 -28.363,-17.191 0,0 8.584,-4.089 28.42,-14.053 z m -144.541,78.554 -27.555,-47.372 -28.049,47.372 -21.157,-1.45 39.854,-67.188 -35.92,-58.004 21.652,-1.935 25.584,43.021 25.59,-41.086 20.174,0.483 -33.459,57.039 36.9,68.638 z" id="path824" />
      <path fill="#244b81" d="m 324.25651,144.14322 c -52.643,-199.915 -293.910004,-145.8300001 -310.436004,0 33.476,18.836 41.072,23.263 41.072,23.263 0,0 -12.938,8.272 -41.186,26.214 35.913,192.189 296.232004,155.255 310.498004,-0.234 -26.271,-16.467 -41.77,-26.063 -41.77,-26.063 0,0 35.423,-19.799 41.822,-23.18 z m -130.838,87.512 -27.553,-47.361 -28.043,47.361 -21.154,-1.45 39.848,-67.176 -35.912,-57.992 21.646,-1.935 25.582,43.012 25.586,-41.078 20.17,0.483 -33.457,57.025 36.898,68.625 z" id="path826" />
    </g>
  </switch>
</svg>
`

export default icon
