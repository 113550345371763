import { Typography, Link } from '@mui/material'
import type { FallbackRender } from '@sentry/react'

import { AppRoutes } from '@/config/routes'
import WarningIcon from '@/public/images/notifications/warning.svg'

import css from '@/components/common/ErrorBoundary/styles.module.css'
import CircularIcon from '../icons/CircularIcon'

const ErrorBoundary: FallbackRender = ({ error, componentStack }) => {
  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <Typography variant="h3" color="text.primary">
          Something went wrong,
          <br />
          please try again.
        </Typography>

        <CircularIcon icon={WarningIcon} badgeColor="warning" />

        <Link href={AppRoutes.welcome.index} color="primary" mt={2}>
          Go home
        </Link>
      </div>
    </div>
  )
}

export default ErrorBoundary
