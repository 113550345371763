import { CYPRESS_MNEMONIC, WC_PROJECT_ID } from '@/config/constants'
import type { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import type { InitOptions } from '@web3-onboard/core'
import injectedWalletModule, { ProviderLabel } from '@web3-onboard/injected-wallets'
import walletConnect from '@web3-onboard/walletconnect'

import e2eWalletModule from '@/tests/e2e-wallet'
import { CGW_NAMES, WALLET_KEYS } from './consts'
import { SOCIAL_WALLET_OPTIONS } from '@/services/mpc/config'
import { transformProviderFromXinfin } from '@/utils/xinfin'
import XDCPayIcon from '@/utils/XDCPayIcon.svg'

const prefersDarkMode = (): boolean => {
  return window?.matchMedia('(prefers-color-scheme: dark)')?.matches
}

type WalletInits = InitOptions['wallets']
type WalletInit = WalletInits extends Array<infer U> ? U : never

const walletConnectV2 = (chain: ChainInfo) => {
  // WalletConnect v2 requires a project ID
  if (!WC_PROJECT_ID) {
    return () => null
  }

  return walletConnect({
    version: 2,
    projectId: WC_PROJECT_ID,
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '1302',
      },
      themeMode: prefersDarkMode() ? 'dark' : 'light',
    },
    requiredChains: [parseInt(chain.chainId)],
    dappUrl: location.origin,
  })
}

const WALLET_MODULES: {
  [key in WALLET_KEYS.INJECTED | WALLET_KEYS.WALLETCONNECT_V2]: (chain: ChainInfo) => WalletInit
} = {
  [WALLET_KEYS.INJECTED]: () =>
    injectedWalletModule({
      filter: (() => {
        const obj: any = {}

        for (const [, key] of Object.entries(ProviderLabel)) {
          obj[key] = false
        }

        obj['MetaMask'] = true

        return obj
      })(),
      custom: [
        {
          label: 'XDCPay',
          injectedNamespace: 'ethereum' as any,
          checkProviderIdentity: ({ provider }) => {
            const network = provider?.publicConfigStore?._state?.networkVersion
            const isCorrectWallet = network === '50' || network === '51'

            return isCorrectWallet
          },
          getIcon: async () => XDCPayIcon,
          getInterface: async () => ({ provider: transformProviderFromXinfin(window.ethereum as any) }),
          platforms: ['desktop'],
          externalUrl: 'https://chrome.google.com/webstore/detail/xinpay/bocpokimicclpaiekenaeelehdjllofo',
        },
      ],
      displayUnavailable: ['XDCPay'],
    }) as WalletInit,
  [WALLET_KEYS.WALLETCONNECT_V2]: (chain) => walletConnectV2(chain) as WalletInit,
}

export const getAllWallets = (chain: ChainInfo): WalletInits => {
  return Object.values(WALLET_MODULES).map((module) => module(chain))
}

export const isWalletSupported = (disabledWallets: string[], walletLabel: string): boolean => {
  const legacyWalletName = CGW_NAMES?.[walletLabel.toUpperCase() as WALLET_KEYS]
  return !disabledWallets.includes(legacyWalletName || walletLabel)
}

export const getSupportedWallets = (chain: ChainInfo): WalletInits => {
  if (window.Cypress && CYPRESS_MNEMONIC) {
    return [e2eWalletModule(chain.rpcUri) as WalletInit]
  }
  const enabledWallets = Object.entries(WALLET_MODULES).filter(([key]) => isWalletSupported(chain.disabledWallets, key))

  if (enabledWallets.length === 0) {
    return [WALLET_MODULES.INJECTED(chain)]
  }

  return enabledWallets.map(([, module]) => module(chain))
}

export const isSocialWalletEnabled = (chain: ChainInfo | undefined): boolean => {
  if (Object.keys(SOCIAL_WALLET_OPTIONS).length === 0) return false

  if (!chain) return false

  return chain.disabledWallets.every((label) => label !== CGW_NAMES.SOCIAL_LOGIN)
}
