import { useEffect } from 'react'

import { showNotification } from '@/store/notificationsSlice'
import { useAppDispatch } from '@/store'

import { useChainId } from './useChainId'

let seen = false

const useXinfinBetaWarning = () => {
  const chainId = useChainId()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (chainId === '50' || chainId === '51') {
      if (seen) return

      dispatch(
        showNotification({
          variant: 'warning',
          message: `Beta Version: Users are advised to transact with small asset values.`,
          groupKey: 'beta-warning',
        }),
      )

      seen = true
    }
  }, [chainId, dispatch])
}

export default useXinfinBetaWarning
